import { RoofTile } from './rooftile';
import { TileFamily, TileFinish, TileColor, RoofArticle, Material, Underlagstak, MarketStr } from '@/calculation/common';
import { ExtraAssortment, AccessoryColor } from '@/enums';

import { i18n } from '@/i18n';
const { t } = i18n.global;

export class Höganäs extends RoofTile {
  public constructor() {
    super(TileFamily.Strängpressat2kupLertegel, 278, 384, 'L201');
    this.label = 'Höganäs 2-kupig';
    this.key = 'hoganas';
    this.material = Material.Lertegel;
    this.läktavstånd = {
      [MarketStr.Sverige]: {
        min: 375,
        angle14to18: 375,
        angle18to22: 375,
        angle22to80: 375,
      },
      [MarketStr.Norge]: {
        min: 375,
        angle14to18: 375,
        angle18to22: 375,
        angle22to80: 375,
      },
      [MarketStr.Tyskland]: {
        min: 375,
        angle14to18: 375,
        angle18to22: 375,
        angle22to80: 375,
      },
    };
    this.avstTillFörstaLäkt = {
      [MarketStr.Sverige]: 340,
      [MarketStr.Norge]: 340,
      [MarketStr.Tyskland]: 340,
    };

    this.itemNos = {
      [RoofArticle.TakPanna]: 'L20124',
      [RoofArticle.NockPanna]: 'H30024',
      [RoofArticle.NockPannaAlt]: 'H30424',
      [RoofArticle.NockBörjan]: 'H31524',
      [RoofArticle.NockSlut]: 'H31624',
      [RoofArticle.NockBörjanGavel]: 'H31824',
      [RoofArticle.NockSlutGavel]: 'H32024',
      [RoofArticle.BörjanValm]: 'H31024',
      [RoofArticle.BörjanValmAlt]: 'H32124',
      [RoofArticle.NockBörjanAlt]: 'H32124',
      [RoofArticle.NockSlutAlt]: 'H32224',
      [RoofArticle.NockTBörjan]: 'H36024',
      [RoofArticle.NockTSlut]: 'H36124',
      [RoofArticle.ValmKlocka]: 'H35024',
      [RoofArticle.ValmKlockaAlt]: 'H35224',
      [RoofArticle.Ventilationshuv]: 'L52224',
      [RoofArticle.AvluftarePlåt]: 'L51224',
      [RoofArticle.Glidskydd]: '072924',
      [RoofArticle.TakkrokJP]: '093990',
      [RoofArticle.SKlammer]: 'L94091',
      [RoofArticle.Takpannekrok]: '094091',
      [RoofArticle.Nockskruv]: '094391',
      [RoofArticle.Nockklammer]: 'H114724',
      [RoofArticle.NockklammerAlt]: 'H124724',
      [RoofArticle.NockKleeblatt]: 'H124720',
      [RoofArticle.Valmtätning300]: '091620',
      [RoofArticle.Valmtätning370]: '090720',
      [RoofArticle.Fågelband]: '092020',
      [RoofArticle.Fågelband5mSvart]: '092120',
      [RoofArticle.Fågelband5mRöd]: '092124',
      [RoofArticle.VentiFågelband]: '091520',
      [RoofArticle.Nockbrädshållare]: '065190',
      [RoofArticle.EasyClip]: '067391',

      // Roof Security
      [RoofArticle.Bärläktsteg45]: '076120',
      [RoofArticle.Bärläktsteg25]: '076120',
      [RoofArticle.KoppladeTaksteg]: '084120',
      [RoofArticle.InfästningKoppladeTaksteg]: '083990',
      [RoofArticle.Taklucka]: '083820',
      [RoofArticle.Säkerhetskrok]: '070120',
      [RoofArticle.Snökrok]: '081420',
      [RoofArticle.Fästögla]: '171690',
      [RoofArticle.SnowSlideObstacle]: '081790',
      [RoofArticle.SnowSlideObstacleKonsol]: '081890',
      [RoofArticle.IsStoppare]: '083020',
    };

    this.marketSpecificItemNos = {
      [MarketStr.Norge]: {
        [RoofArticle.Valmtätning300]: '092420',
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public isSelectable(
    lAvstånd?: number | null,
    taklutning?: { min: number; max: number } | null,
    underlagstak?: Underlagstak | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    market?: MarketStr | null,
  ): { valid: boolean; message: string } {
    // Läktavstånd = 375
    if (lAvstånd && lAvstånd !== 375) return { valid: false, message: t('validation.notice.disabled_due_to_lakt') };
    if (taklutning && (!this.angleInRange(taklutning.min, { min: 18, max: 80 }) || !this.angleInRange(taklutning.max, { min: 18, max: 80 })))
      return { valid: false, message: t('validation.notice.disabled_due_to_angle') };
    if (underlagstak && underlagstak !== Underlagstak.RåspontMedPapp) return { valid: false, message: t('validation.notice.disabled_due_to_underlagstak') };

    return { valid: true, message: '' };
  }

  public bärläktAvståndNock(angle: number, underlagstak: Underlagstak): number {
    // TODO
    const ang = Number(angle.toFixed(1));
    if (underlagstak == Underlagstak.RåspontMedPapp || underlagstak == Underlagstak.LättMedStröläkt) {
      if (ang >= 14 && ang <= 22.5) return 55;
      if (ang >= 22.6 && ang <= 27.5) return 50;
      if (ang >= 27.6 && ang <= 32.5) return 45;
      if (ang >= 32.6 && ang <= 37.5) return 40;
      if (ang >= 37.6 && ang <= 42.5) return 35;
      if (ang >= 42.6 && ang <= 47.5) return 30;
      if (ang >= 47.6 && ang <= 52.5) return 25;
      if (ang >= 52.6 && ang <= 57.5) return 20;
      return 20;
    } else {
      if (ang >= 14 && ang <= 22.5) return 50;
      if (ang >= 22.6 && ang <= 27.5) return 45;
      if (ang >= 27.6 && ang <= 32.5) return 40;
      if (ang >= 32.6 && ang <= 37.5) return 35;
      if (ang >= 37.6 && ang <= 42.5) return 30;
      if (ang >= 42.6 && ang <= 47.5) return 25;
      if (ang >= 47.6 && ang <= 52.5) return 20;
      if (ang >= 52.6 && ang <= 57.5) return 15;
      return 15;
    }
  }

  public getAccessoryOptions(
    assortment: ExtraAssortment,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { color, finish, accessoryColor }: { color: TileColor; finish: TileFinish; accessoryColor: AccessoryColor },
  ) {
    const items: string[] = [];
    switch (assortment) {
      case ExtraAssortment.VentilationGenomforing:
        switch (accessoryColor) {
          case AccessoryColor.GalvanizedGreySilver:
          case AccessoryColor.Black:
          case AccessoryColor.BrickRed:
            items.push('L51224');
            break;
        }
        break;
    }

    return items;
  }
}
